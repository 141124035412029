import React, { Fragment } from 'react';
import { Formik, Form, Field, FieldArray } from 'formik';

// Needs validation to cope with emtpy fields as a mimimum
const NameList = ({ playerNameList, handleSubmit }) => (
  <Formik
    onSubmit={(props, actions) => {
      props.handleSubmit(props.playerNameList);
    }}
    initialValues={{ playerNameList, handleSubmit }}
    validateOnChange={false}
    render={(props) => (
      <Fragment>
        <div className='logoBadge'>
          <h3 className=''>Finalise Player Names</h3>
        </div>
        <Form className='playerChoices'>
          <FieldArray
            name='players'
            render={(arrayHelpers) => (
              <Fragment>
                {playerNameList.map((playerName, index) => (
                  <div className='inputField' key={index}>
                    <Field
                      className='NameCard'
                      name={`playerNameList.${index}`}
                      onChange={props.handleChange}
                    />
                  </div>
                ))}
              </Fragment>
            )}
          />
          <button className='createButton' type='submit'>
            Create Teams
          </button>
          {/* <DisplayFormikState {...props} /> */}
        </Form>
      </Fragment>
    )}
  />
);

export default NameList;
