import React from 'react';

export const DisplayFormikState = (props) => (
  <div style={{ margin: '1rem 0' }}>
    <h3>Formik State</h3>
    <pre
      style={{
        background: '#f6f8fa',
        fontSize: '.65rem',
        padding: '.5rem',
      }}
    >
      <strong>props</strong> = {JSON.stringify(props, null, 2)}
    </pre>
  </div>
);

export const shuffleList = (list) => {
  // The Fisher-Yates shuffle
  let i = 0,
    j = 0,
    temp = null;

  for (i = list.length - 1; i > 0; i -= 1) {
    j = Math.floor(Math.random() * (i + 1));
    temp = list[i];
    list[i] = list[j];
    list[j] = temp;
  }

  return list;
};
