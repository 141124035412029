import { NUMBER_OF_PLAYERS } from '../data/defaults';

const initial = {
  playerNameList: [],
  teamA: [],
  teamB: [],
  waterCarrier: -1,
  listCreated: false,
  teamsCreated: false,
  numberOfPlayersChosen: false,
  numberOfPlayers: NUMBER_OF_PLAYERS,
};
export default initial;
