import React from 'react';
import NameCard from './NameCard';

const TeamList = ({ teamlist, playerNameList }) => (
  <div className='TeamList'>
    {teamlist.map((pointer, index) => {
      return (
        <NameCard
          className='NameCard'
          playerName={playerNameList[pointer]}
          key={index}
        />
      );
    })}
  </div>
);

export default TeamList;
