export const NUMBER_OF_PLAYERS = 10;

export const sampleNames = [
  'Johann Reinhold Forster',
  'Isabelle Aubret',
  'Daniel Boone',
  'Ernst May',
  'Goran Pandev',
  'Collins Obuya',
  'Aleks Marić',
  'Bruce Norris',
  'Johann Reinhold Forster',
  'Michael Fishman',
  "Charles de L'Ecluse",
  'Frances Drake',
  'Tiphaigne de la Roche',
  'Heath Miller',
  'Mikko Kavén',
  'George L. Street III American navy captain',
  'Sam Myers',
  'J. Robert Hooper',
  'Bobbie Gentry',
  'David Gross',
  "Charles de L'Ecluse",
  'Erasmus Reinhold',
  'Alex Rodriguez',
  'Georges Brassens',
  'Jacques Sirmond',
  'Émile Genest',
  'Shaggy',
  'Ludovico Sforza',
  'Nelson Pereira dos Santos',
  'Robert Torti',
  'Bob Odenkirk',
  'Helmut Lotti',
  'Gil Reyes',
  'David Chacón Perez',
  'Beth Ditto',
  'Salvatore Di Vittorio',
  'Jay Johnston',
  'Doni',
  'Jesse Tyler Ferguson',
  'Ronnie Arniell',
  'Ryan Griffen',
  'Tim Shadbolt',
  'Karl Mueller',
  'Eason Chan',
  'Bert Trautmann',
  'Sir Allan Napier MacNab Bt',
  'Ronnie Arniell',
  'Prince Andrew',
  'Winston Bogarde',
  'Forest Able',
];
