import React, { Component } from 'react';
import { Formik } from 'formik';
import axios from '../../data/axios';

import NameList from '../NameList';
import TeamList from '../TeamList';
import WaterCarrier from '../WaterCarrier';

import { sampleNames } from '../../data/defaults';
import { shuffleList } from '../../utils';
import initial from '../../data/initial';

// import { DisplayFormikState } from '../../utils';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = { ...initial };
    this.updatePlayers = this.updatePlayers.bind(this);
    this.getNames = this.getNames.bind(this);
    this.assignTeams = this.assignTeams.bind(this);
  }

  getNames = (number) => {
    axios
      .post(`?n=${number}`, { type: 'string', ipsum: 'name' })
      .then(({ data }) => {
        this.setState({ playerNameList: data });
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  getNameList = (sampleNames, numberOfPlayers) => {
    let tempNameList = [];
    sampleNames = shuffleList(sampleNames);
    for (let i = 0; i < numberOfPlayers; i++) {
      tempNameList[i] = sampleNames[i];
    }
    return tempNameList;
  };

  assignTeams = (playerNameList) => {
    let teamA = [];
    let teamB = [];
    let waterCarrier = -1;

    let numberPerTeam =
      playerNameList.length % 2 === 0
        ? playerNameList.length / 2
        : (playerNameList.length - 1) / 2;

    if (playerNameList.length % 2 === 1) {
      let random = Math.floor(Math.random() * playerNameList.length);
      waterCarrier = random;
    }

    playerNameList.map((name, index) => {
      if (
        teamA.length < numberPerTeam &&
        teamB.length < numberPerTeam &&
        waterCarrier !== index
      ) {
        let x = Math.floor(Math.random() * 2 + 1);
        if (x === 1) {
          teamA.push(index);
        } else {
          teamB.push(index);
        }
      } else if (teamA.length === numberPerTeam && waterCarrier !== index) {
        teamB.push(index);
      } else if (teamB.length === numberPerTeam && waterCarrier !== index) {
        teamA.push(index);
      }
      return name;
    });

    this.setState({
      playerNameList,
      teamA,
      teamB,
      waterCarrier,
      listCreated: false,
      teamsCreated: true,
    });
  };

  updatePlayers(numberOfPlayers, seedFromApi, useScores, playerNameList) {
    if (numberOfPlayers < 4) numberOfPlayers = 4;
    if (numberOfPlayers > 50) numberOfPlayers = 50;
    if (seedFromApi) {
      playerNameList = this.getNameList(sampleNames, numberOfPlayers);
    } else {
      for (let i = 0; i < numberOfPlayers; i++) {
        playerNameList[i] = '';
      }
    }

    this.setState({
      playerNameList,
      numberOfPlayers,
      seedFromApi,
      useScores,
      numberOfPlayersChosen: true,
      listCreated: true,
    });
  }

  render() {
    if (!this.state.numberOfPlayersChosen) {
      return (
        <div className='mainContainer'>
          <Formik
            onSubmit={(
              { numberOfPlayers, seedFromApi, useScores },
              actions
            ) => {
              this.updatePlayers(
                numberOfPlayers,
                seedFromApi,
                useScores,
                this.state.playerNameList
              );
            }}
            initialValues={{
              numberOfPlayers: this.state.numberOfPlayers,
              seedFromApi: false,
              useScores: false,
            }}
            validateOnChange={false}
            render={(props) => (
              <div className='container'>
                <header className='logoBadge'>
                  <h1 className='logo'>Team Maker</h1>
                </header>
                <form className='entryChoices' onSubmit={props.handleSubmit}>
                  <div className='choice'>
                    <label htmlFor='number'>How many players? (max. 50)</label>
                    <input
                      id='numberOfPlayers'
                      placeholder={props.numberOfPlayers}
                      type='number'
                      value={props.values.numberOfPlayers}
                      onChange={props.handleChange}
                    />
                  </div>
                  <div className='choice'>
                    <label htmlFor='seedFromApi'>
                      Would you like me to generate names?
                    </label>
                    <input
                      id='seedFromApi'
                      type='checkbox'
                      value={props.seedFromApi}
                      onChange={props.handleChange}
                    />
                  </div>
                  {/* <label htmlFor='useScores'>
                  Would you like to create teams with even ability?
                </label>
                <input
                  id='useScores'
                  type='checkbox'
                  value={props.useScores}
                  onChange={props.handleChange}
                /> */}
                  <button className='createButton' type='submit'>
                    Submit
                  </button>
                  {/* <DisplayFormikState {...props} /> */}
                </form>
              </div>
            )}
          />
        </div>
      );
    }

    if (this.state.listCreated) {
      return (
        <div className='mainContainer'>
          <NameList
            handleSubmit={this.assignTeams}
            playerNameList={this.state.playerNameList}
          />
        </div>
      );
    }

    if (this.state.teamsCreated) {
      return (
        <div className='teamContainer'>
          <div className='teamGroup'>
            <div className='logoBadge'>
              <h1>TEAM A</h1>
            </div>
            <TeamList
              playerNameList={this.state.playerNameList}
              teamlist={this.state.teamA}
            />
          </div>

          <div className='teamGroup'>
            <div className='logoBadge'>
              <h1>TEAM B</h1>
            </div>
            <TeamList
              playerNameList={this.state.playerNameList}
              teamlist={this.state.teamB}
            />
          </div>

          {this.state.waterCarrier > -1 ? (
            <WaterCarrier
              playerNameList={this.state.playerNameList}
              waterCarrier={this.state.waterCarrier}
            />
          ) : null}
        </div>
      );
    }

    return null;
  }
}

export default App;
