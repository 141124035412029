import React from 'react';
import NameCard from './NameCard';

const WaterCarrier = ({ playerNameList, waterCarrier }) => (
  <div>
    <h1>Our Water Carrier</h1>
    <ul className='WaterCarrier'>
      <NameCard playerName={playerNameList[waterCarrier]} />
    </ul>
  </div>
);

export default WaterCarrier;
